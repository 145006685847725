<template>
  <div class="space-y-4">
    <asom-modal
      title="Sign Cash Declaration Form"
      v-model="showModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="onCloseModal"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-alert
      class="md:col-span-2 mb-4"
      v-if="error"
      variant="error"
      :error-message="error"
    />
    <asom-card>
      <cash-declaration-summary-card
        :referenceNo="formData.referenceNo"
        :sourceOfCash="formData.sourceOfCash"
        :sourceOfCashEnum="formData.sourceOfCashEnum"
        :stationName="formData.stationName"
        :remarks="formData.remarks"
        :teBagNo="formData.teBagNo"
        :count1Cents="formData.count1Cents"
        :count2Cents="formData.count2Cents"
        :count5Cents="formData.count5Cents"
        :count10Cents="formData.count10Cents"
        :count20Cents="formData.count20Cents"
        :count50Cents="formData.count50Cents"
        :count1Dollar="formData.count1Dollar"
        :count1DollarNote="formData.count1DollarNote"
        :count2Dollar="formData.count2Dollar"
        :count5Dollar="formData.count5Dollar"
        :count10Dollar="formData.count10Dollar"
        :count20Dollar="formData.count20Dollar"
        :count25Dollar="formData.count25Dollar"
        :count50Dollar="formData.count50Dollar"
        :count100Dollar="formData.count100Dollar"
        :count500Dollar="formData.count500Dollar"
        :count1000Dollar="formData.count1000Dollar"
        :count10000Dollar="formData.count10000Dollar"
        :transactionData="formData.transactionData"
        :psmName="formData.psmName"
      />

      <div class="mt-10 pb-12 bg-white sm:pb-16">
        <div class="relative">
          <div class="absolute inset-0 h-1/2 bg-gray-50"></div>
          <div class="relative">
            <div class="mx-auto">
              <dl class="rounded-lg bg-gray-100 shadow-lg grid sm:grid-cols-2">
                <div
                  class="flex flex-col border-t border-b border-gray-100 p-6 text-start sm:border-0 sm:border-l sm:border-r"
                >
                  <div class="bold-text">Staff (Witness)</div>
                  <div>{{ formData.witnessName }}</div>
                  <div class="mt-5">
                    <asom-form-field
                      label="Signature (Witness)"
                      required
                      :state="inputStates('witnessSignature')"
                      error="Signature is required"
                    >
                      <asom-signature-pad
                        v-model="witnessSignature"
                        :width="400"
                      />
                    </asom-form-field>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-2 flex justify-end space-x-4">
        <asom-button
          text="Cancel"
          variant="secondary"
          @click="$router.push('/cash-mgnt/cash-declaration-form')"
        />
        <asom-button
          text="Confirm"
          @click="submitClicked"
          :loading="isSubmitting"
        />
      </div>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  signCashDeclarationForm,
  getCashDeclarationFormDetails,
} from "../../../services/cashManagement.service";
import { uploadFile } from "@/services/file.service";
import { imageDataToBlob } from "../../../helpers/imageDataToBlob";
import CashDeclarationSummaryCard from "./_CashDeclarationSummaryCard.vue";
import { formDataMixin, modalMixin } from "./_mixins";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  name: "CashDeclarationWitnessSign",
  components: {
    CashDeclarationSummaryCard,
  },
  mixins: [inputStates,modalMixin,formDataMixin],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      witnessSignature: null,
      isLoading: false,
      isSubmitting: false,
      error: null,
      signatureId: null,
    };
  },
  validations() {
    return {
      witnessSignature: { required },
    };
  },
  mounted() {
    this.getCDFDetails();
  },
  methods: {
    async getCDFDetails() {
      const resp = await getCashDeclarationFormDetails({
        cashDeclarationFormId: this.$route.params.cashDeclarationFormId,
      });
      if (resp.success) {
        this.setFormData(resp.payload);
      } else return false;
    },
    submitClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.showModal = true;
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      this.error = "";
      await this.uploadSignature();
      if (!this.error && this.signatureId) {
        const result = await signCashDeclarationForm({
          formId: this.$route.params.cashDeclarationFormId,
          signatureFileId: this.signatureId,
        });
        if (result.success) {
          this.$router.push("/cash-mgnt/cash-declaration-form");
        } else {
          this.error = result.payload;
          this.$scrollTop();
        }
      }

      this.isSubmitting = false;
      this.showModal = false;
    },
    async uploadSignature() {
      var blob = imageDataToBlob(this.witnessSignature, "witnessSign");
      await this.upload(blob);
    },
    async upload(blob) {
      const resp = await uploadFile([blob], ATTACHMENT_CATEGORIES.CASH_MANAGEMENT);
      if (resp.success) {
        this.signatureId = get(resp, "payload.files.0.fileId");
      } else {
        this.error = resp.payload;
      }
    },
  },
};
</script>

<style scoped>
.denominations {
  @apply px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900;
}
.noOfNotes {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-500;
}
.totalNotes {
  @apply justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;
}
.value {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-500;
}
.bold-text {
  @apply text-gray-500 font-bold;
}
</style>
